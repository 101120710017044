import { render, staticRenderFns } from "./403.vue?vue&type=template&id=4488ed7f&scoped=true"
import script from "./403.vue?vue&type=script&lang=js"
export * from "./403.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4488ed7f",
  null
  
)

export default component.exports